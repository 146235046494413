<template>
  <el-container id="app-menu">
    <el-aside
      theme="light"
      class="layout-sider"
      width="230px"
    >
      <div style="display: flex; justify-content: space-between; padding: 10px 10px; ">
        <el-input
          v-model.trim="keyword"
          placeholder="搜索"
          suffix-icon="el-icon-search"
          @keyup="onSearch"
          @search="onSearch"
        />
      </div>
      <div>

        <el-tabs class="el-tabs" v-model="activeTabName" :stretch="true" @tab-click="handleTabClick">
          <el-tab-pane label="好友" name="first">
            <vue-scroll :ops="ops"  style="height: calc(100vh - 120px)" @handle-scroll="handleScroll" v-if="friendListFilter.length>0">
              <div class="chat-item" v-for="(item, index) in friendListFilter" :key="index"
                :class="{active: current && current.friend_user_id == item.friend_user_id}"
                @click="selectUser(item,index)">
                <div class="avatar">
                  <el-image :src="thumb(item.friendInfo.thumb_avatar)" />
                </div>
                <div class="user-info">
                  <div class="hd">
                    <span class="name line1">{{ item.friendInfo.nickname }}</span>
                  </div>
                  <div class="bd">
                    {{item.login_info}}
                  </div>
                </div>
              </div>
            </vue-scroll>
            <el-empty v-else description="暂无好友列表" status="1"></el-empty>
          </el-tab-pane>
          <el-tab-pane label="群聊" name="second">
            <vue-scroll :ops="ops"  style="height: calc(100vh - 120px)" @handle-scroll="handleScroll" v-if="groupListFilter.length>0">
              <div class="chat-item" v-for="(item, index) in groupListFilter" :key="index"
                :class="{active: groupCurrent && groupCurrent.groupId == item.groupId}"
                @click="selectGroup(item,index)">
                <div class="avatar">
                  <el-image :src="thumb(item.thumb_avatar)" />
                </div>
                <div class="user-info">
                  <div class="hd">
                    <span class="name line1">{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </vue-scroll>
            <el-empty v-else description="暂未加入群聊" status="1"></el-empty>
          </el-tab-pane>
          <el-tab-pane :label="`申请` + (friendApplyUnread > 0 ? ' (' + friendApplyUnread + ')' : '')" name="three">
            <vue-scroll :ops="ops"  style="height: calc(100vh - 120px)" @handle-scroll="handleScroll" v-if="friendApplyList.length>0">
              <div class="chat-item" v-for="(item, index) in friendApplyList" :key="index"
                :class="{active: applyCurrent && applyCurrent.apply_user_id == item.apply_user_id}"
                @click="selectApply(item,index)">
                <div class="avatar">
                  <el-badge is-dot v-if="item.is_read < 0">
                    <el-image :src="thumb(item.userInfo.thumb_avatar)" />
                  </el-badge>
                  <el-image v-else :src="thumb(item.userInfo.thumb_avatar)" />
                </div>
                <div class="user-info">
                  <div class="hd">
                    <span class="name line1">{{ item.userInfo.nickname }}</span>
                  </div>
                  <div class="bd">
                    {{item.extra_message}}
                  </div>
                  <div class="bd">
                    {{ dateTimeFormat(item.create_time, true) }}
                  </div>
                </div>
              </div>
            </vue-scroll>
            <el-empty v-else description="暂无申请" status="1"></el-empty>
          </el-tab-pane>
        </el-tabs>
        <!--
        <vue-scroll :ops="ops"  style="height: calc(100vh - 65px)" @handle-scroll="handleScroll" v-if="friendListFilter.length>0">
          <a href="javascript:void(0)" @click="showType = 'friendApply'">
            <div class="chat-item-a">
              <div>我的好友</div>
              <span v-if="friendApplyUnread > 0">{{friendApplyUnread}}</span>
            </div>
          </a>
          <a href="javascript:void(0)" @click="showType = 'myGroup'">
            <div class="chat-item-a">
              我的群聊
            </div>
          </a>

        </vue-scroll>
        <el-empty v-else description="暂无好友列表" status="1"></el-empty>
        -->
      </div>
    </el-aside>
    <el-main>
      <el-empty v-if="showType == 'friendInfo' && current.user_id == ''" description="好友信息">

      </el-empty>
      <div v-if="showType == 'friendInfo' && current.user_id != ''" :class="{'msg-window': true}">
        <div title="基本资料">
          <div class="example">
            <el-form ref="baseForm" label-width="120px" :modelValue="current">
                <div class="avatar-upload">
                  <el-image class="avatar-m avatar-circle" style="width: 150px; height: 150px;"
                    :src="thumb(current.friendInfo.avatar)"></el-image>
                </div>
                <el-form-item label="昵称">
                  <el-input v-model="current.friendInfo.nickname" disabled />
                </el-form-item>
                <el-form-item label="个性签名">
                  <el-input type="textarea" v-model="current.friendInfo.motto" disabled />
                </el-form-item>
                <div style="text-align: center">
                  <el-button type="primary" @click="newConversation('private', current.friend_user_id)">发起会话</el-button>
                </div>
            </el-form>
          </div>
        </div>
      </div>
      <div v-if="showType == 'groupInfo' && groupCurrent.groupId != ''" :class="{'msg-window': true}">
        <div title="基本资料">
          <div class="example">
            <el-form ref="baseForm" label-width="120px" :modelValue="groupCurrent">
                <div class="avatar-upload">
                  <el-image class="avatar-m avatar-circle" style="width: 150px; height: 150px;"
                    :src="thumb(groupCurrent.thumb_avatar)"></el-image>
                </div>
                <el-form-item label="群名">
                  <el-input v-model="groupCurrent.name" disabled />
                </el-form-item>
                <el-form-item label="群介绍">
                  <el-input type="textarea" v-model="groupCurrent.introduction" disabled />
                </el-form-item>
                <div style="text-align: center">
                  <el-button type="primary" @click="newConversation('group', groupCurrent.groupId)">发起会话</el-button>
                </div>
            </el-form>
          </div>
        </div>
      </div>
      <div v-if="showType == 'friendApply'">
        <el-table
          :data="friendApplyList"
          border>
          <el-table-column
            prop="avatar"
            label="头像"
            width="80">
            <template slot-scope="scope">
              <el-image class="avatar-m" :src="thumb(scope.row.userInfo.thumb_avatar)" :preview-src-list="[thumb(scope.row.userInfo.avatar)]"></el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="money"
            label="UserID"
            width="150">
            <template slot-scope="scope">
              {{ scope.row.userInfo.user_id }}
            </template>
          </el-table-column>
          <el-table-column
            prop="money"
            label="昵称"
            width="150">
            <template slot-scope="scope">
              {{ scope.row.userInfo.nickname }}
            </template>
          </el-table-column>
          <el-table-column
            prop="line_id"
            label="代理线"
            width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.line_id">{{ scope.row.line_id }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="money"
            label="附言">
            <template slot-scope="scope">
              {{ scope.row.extra_message }}
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="申请时间"
            width="160">
            <template slot-scope="scope">
              {{ dateTimeFormat(scope.row.create_time, true) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="note"
            label="操作"
            width="160">
            <template slot-scope="scope">
              <el-button-group>
                <el-button type="primary" @click="handlePassFriend(scope.row)">通过</el-button>
                <el-button type="danger" @click="handleRefuseFriend(scope.row)">拒绝</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </el-container>
</template>
<script>

import {
  mapState
} from 'vuex'
import { dateTimeFormat, thumb } from '@/utils/utils'

import MsgWindow from "@/views/chat/components/msg-window";
import {
  YeIMUniSDK,
  YeIMUniSDKDefines
} from '@/utils/wzJun1-YeIM-Uni-SDK/js_sdk/yeim-uni-sdk.min';
import { requestHttp } from "@/api/main";

export default {
  name: 'ChatList',
  components: {
    MsgWindow
  },
  data() {
    return {
      keyword: '',
      menu: {},
      activeTabName: 'first',
      current: {
        id: 0,
        user_id: '',
        friend_user_id: ''
      },
      groupCurrent: {
        groupId: '',
        name: ''
      },
      applyCurrent: {
        apply_user_id: '',
        user_id: ''
      },
      showType: 'friendInfo',
      keys: [],
      ops: {
        vuescroll: {
          mode: 'native',
          enable: false,
          tips: {
            deactive: 'Push to Load',
            active: 'Release to Load',
            start: 'Loading...',
            beforeDeactive: 'Load Successfully!'
          },
          auto: false,
          autoLoadDistance: 0,
          pullRefresh: {
            enable: false
          },
          pushLoad: {
            enable: true,
            auto: true,
            autoLoadDistance: 10
          }
        },
        bar: {
          background: '#393232',
          opacity: '.5',
          size: '5px'
        }
      },
      isLoad: true,
      records: []
    };
  },
  computed: {
    ...mapState({
      user: state => state.yeim.user,
      friendList: state => state.yeim.friendList,
      friendApplyUnread: state => state.yeim.friendApplyUnread,
      friendApplyList: state => state.yeim.friendApplyList,
      groupList: state => state.yeim.groupList
    }),
    friendListFilter() {
      const that = this
      return this.$store.state.yeim.friendList.filter((item) => {
        if (that.keyword !== '' && item.friendInfo.nickname && item.friendInfo.nickname.toUpperCase().indexOf(that.keyword.toUpperCase()) === -1) {
          return false;
        }
        return true
      })
    },
    groupListFilter() {
      const that = this
      return this.$store.state.yeim.groupList.filter((item) => {
        if (that.keyword !== '' && item.name && item.name.toUpperCase().indexOf(that.keyword.toUpperCase()) === -1) {
          return false;
        }
        return true
      })
    }
  },
  watch: {
    showType(val) {
      if (val == 'friendApply') {
        YeIMUniSDK.getInstance().setApplyListRead();
        this.$store.state.yeim.friendApplyUnread = 0
      }
    }
  },
  methods: {
    dateTimeFormat,
    thumb,
    onSearch() {

    },
    handleTabClick(e) {
      this.keyword = ''
      if (this.activeTabName == 'three') {
        this.showType = 'friendApply'
      }
    },
    handleScroll() {

    },
    selectUser(item) {
      console.log(item)
      this.showType = 'friendInfo'
      this.current = item
    },
    selectGroup(item) {
      console.log(item)
      this.showType = 'groupInfo'
      this.groupCurrent = item
    },
    selectApply(item) {
      // this.showType = 'friendInfo'
      this.applyCurrent = item
    },
    fetchApplyList() {
      YeIMUniSDK.getInstance().getFriendApplyList({
        page: this.page,
        limit: 20,
        cloud: true,
        success: (res) => {

        },
        fail: (err) => {
          console.log(err)
        }
      });
    },
    handlePassFriend(e) {
      const that = this
      if (e.line_id != this.user.line_id) {
        this.$confirm('这个客户不是你的客户，是否继续添加？', '提示').then(function () {
          YeIMUniSDK.getInstance().acceptApply({
            id: e.id,
            success: (res) => {
              that.$message.success({ message: res.message, offset: 100 })
            },
            fail: (res) => {
              that.$message.error({ message: res.message, offset: 100 })
            }
          });
        })
      }else {
        YeIMUniSDK.getInstance().acceptApply({
          id: e.id,
          success: (res) => {
            that.$message.success({ message: res.message, offset: 100 })
          },
          fail: (res) => {
            that.$message.error({ message: res.message, offset: 100 })
          }
        });
      }
    },
    handleRefuseFriend(e) {
      const that = this
      YeIMUniSDK.getInstance().refuseApply({
        id: e.id,
        success: (res) => {
          that.$message.success({ message: res.message, offset: 100 })
          that.fetchApplyList()
        },
        fail: (res) => {
          that.$message.error({ message: res.message, offset: 100 })
        }
      });
    },
    newConversation(type, friendUserId) {
      this.$router.push({ name: 'ChatList', params: { type: type, toId: friendUserId } })
    },
    scrollTop() {

    },
    scrollHandler() {

    }
  }
};
</script>
<style lang="less" scoped>
#app-menu {
  height: 100%;
  text-align: center;

  .layout-sider {
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    background-color: #FAFAFA;
    overflow: auto;
  }

  .scroll-box {
    flex: 1;
    height: 500px;
    overflow: hidden;
  }

  .chat-item-a {
    background: #f5f5f5;
    padding: 20px 10px;
    border-bottom: 1px solid #fff;
    color: #1d42ab;
    text-align: left;
    display: flex;
    justify-content: space-between;

    span {
      background: red;
      width: 20px;
      height: 20px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      border-radius: 50%;
    }
  }

  a {
    text-decoration: none;
  }

  .chat-item {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 12px 10px;
    height: 74px;
    box-sizing: border-box;
    border-left: 3px solid transparent;
    cursor: pointer;
    text-align: left;

    &:hover {
      background: #EFF0F1;
    }

    &.active {
      background: #EFF0F1;
      border-left: 3px solid #1890FF;
    }

    .avatar {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 8px;

      .el-image {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }

      .status {
        position: absolute;
        right: 3px;
        bottom: 0;
        width: 8px;
        height: 8px;
        background: #48D452;
        border: 1px solid #fff;
        border-radius: 50%;

        &.off {
          background: #999999;
        }
      }
    }

    .user-info {
      width: 155px;
      margin-left: 12px;
      margin-top: 5px;
      font-size: 16px;

      .hd {
        display: flex;
        justify-content: left;
        align-items: center;
        color: rgba(0, 0, 0, 0.65);

        .name {
          max-width: 67%;
        }

        .label {
          margin-left: 5px;
          color: #3875EA;
          font-size: 12px;
          background: #D8E5FF;
          border-radius: 2px;
          padding: 1px 5px;

          &.H5 {
            background: #FAF1D0;
            color: #DC9A04;
          }

          &.wechat {
            background: rgba(64, 194, 73, 0.16);
            color: #40C249;
          }

          &.pc {
            background: rgba(100, 64, 194, 0.16);
            color: #6440C2;
          }
        }
      }

      .bd {
        margin-top: 3px;
        font-size: 12px;
        color: #8E959E;
        text-align: left;
      }
    }
  }

  .msg-window {
    .hide {
      display: none;
    }
  }
}

</style>
